p {
  color: #61dafb
}

.App {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Playbutton {
  cursor: pointer;
  background-color: transparent;
  color: #61dafb;
  border: 1px solid #61dafb; /* Green */
}

.UpButton {
  cursor: pointer;
  background-color: transparent;
  color: #61dafb;
  border: 1px solid #61dafb; /* Green */
}

.RuleSelect {
  cursor: pointer;
  background-color: transparent;
  color: #61dafb;
  border: 1px solid #61dafb; /* Green */
}

.ZoneList {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;
}

.ZoneLabel {
  border: 1px solid #61dafb; /* Green */
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: row;
}

.RuleSelectOption {
  color: #424242;
}

.DisabledRuleButton {
  background-color: transparent;
  color: #fb61fb;
  border: 1px solid #61dafb; /* Green */
}

.CanvasContainer {
  border: 1px solid #61dafb; /* Green */
}


/* slider style credits: https://codepen.io/jonnaru/ */
.slider {
  -webkit-appearance: none;
  height: 10px;
  background: #000;
  outline: none;
  border: 1px solid #61dafb;
  border-radius: 8px;
}

/* for chrome/safari */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 30px;
  background: #000;
  cursor: pointer;
  border: 2px solid #61dafb;
  border-radius: 4px;
}

/* for firefox */
.slider::-moz-range-thumb {
  width: 20px;
  height: 30px;
  background: #000;
  cursor: pointer;
  border: 5px solid #61dafb;
  border-radius: 4px;
}
